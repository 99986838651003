
import organicTabs from '../vendors/organic-tabs';
import owlCarousel from '../vendors/owl.carousel.min';
import Typed from '../vendors/typing';
import gsap from '../vendors/gsap.min';
import ScrollTrigger from '../vendors/ScrollTrigger.min';
import Draggable from '../vendors/Draggable.min';
import Isotope from '../vendors/isotope.pkgd';
import magnific from '../vendors/magnific-popup.min';

/**
   * Sticky header
   *
   * Starts Here
   *
   */
if (jQuery(window).width() > 1179) {
  if (jQuery("header").length > 0) {

    var header = document.querySelector("header");
    var navbarHeight = header.offsetHeight;
    var lastScrollTop = 0;

    window.onscroll = function () { scrollHide() };

    function scrollHide() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        header.classList.add('nav-up');
      } else {
        header.classList.remove('nav-up');
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }
  }
}
if (jQuery(window).width() < 1179) {
  jQuery(document).on("scroll", function () {
    if (jQuery(document).scrollTop() > 0) {
      jQuery("header, body").addClass("shrink");
    } else {
      jQuery("header, body").removeClass("shrink");
      // var $header_height = jQuery("header").outerHeight();
      // 	jQuery("body").css('padding-top', $header_height+'px');
    }
  });
}

/**
 * Sticky header
 *
 * Ends Here
 *
 */
jQuery(document).ready(function () {

  if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    jQuery("body").addClass("style-for-apple");
  } else {
    jQuery("body").addClass("style-for-window");
  }
  /**
   * Toggle menu for mobile
   */
  // accessible sub menu
  jQuery('.menu-item-has-children > a').focus(function () {
    jQuery(this).siblings('.sub-menu').addClass('focused');
  }).blur(function () {
    jQuery(this).siblings('.sub-menu').removeClass('focused');
  });

  jQuery('.menu-btn').click(function () {
    jQuery(this).toggleClass('active');
    jQuery('.nav-overlay').toggleClass('open');
    jQuery('html, body').toggleClass('no-overflow');
    jQuery('.header-nav ul li.active').removeClass('active');
    jQuery('.header-nav ul.sub-menu').slideUp();
  });
  jQuery.noConflict();

  /**
   * Add span tag to multi-level accordion menu for mobile menus
   */
  jQuery('li').each(function () {
    if (jQuery(this).hasClass('menu-item-has-children')) {
      jQuery(this)
        .find('a:first')
        .after('<span class="submenu-icon"></span>');
    }
  });
  /**
   * Slide Up/Down internal sub-menu when mobile menu arrow clicked
   */
  jQuery(".menu-item-has-children > a").on("click", function (event) {
    event.preventDefault();
  });
  jQuery('.header-nav .menu-item-has-children > a, .header-nav .submenu-icon').click(function () {
    const link = jQuery(this);
    const closestUl = link.closest('ul');
    const parallelActiveLinks = closestUl.find('.active');
    const closestLi = link.closest('li');
    const linkStatus = closestLi.hasClass('active');
    let count = 0;

    closestUl.find('ul').slideUp(function () {
      if (++count === closestUl.find('ul').length) {
        parallelActiveLinks.removeClass('active');
      }
    });

    if (!linkStatus) {
      closestLi.children('ul').slideDown();
      closestLi.addClass('active');
    }
  });

  jQuery(document).on('click', '.tooltip-box .tooltip-icon', function () {
    const parent = jQuery(this).parents('.table-lists-ctn');
    parent.addClass('active');
  });


  jQuery(document).on('click', function (e) {
    const container = jQuery('.tooltip-box');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.parents('.table-lists-ctn').removeClass('active');
    }
  });



  jQuery('.feature-video-area .play-button a').click(function () {
    event.preventDefault();
    const parent = jQuery(this).parents('.feature-video-area');
    parent.addClass('active');
    parent.find('video').trigger('play');
  });

  jQuery('.feature-video-area video').click(function () {
    event.preventDefault();
    const parent = jQuery(this).parents('.feature-video-area');
    parent.removeClass('active');
    parent.find('video').trigger('pause');
  });

  jQuery("#video-tabs").organicTabs({
    speed: 200
  });
  if (jQuery(window).width() < 1199) {
    jQuery('.showroom-video video').trigger('play');
  }

  if (jQuery(window).width() > 1199) {
    gsap.registerPlugin(ScrollTrigger);
    if (jQuery(".hero-guest-video").length > 0) {
      gsap.to('.hero-guest-video', {
        y: 500,
        x: 450,
        scrollTrigger: {
          trigger: '.hero-section',
          start: 'top top',
          end: 'bottom top+=300',
          scrub: true,
          onLeave: () => {
            document.querySelector(".hero-guest-video").classList.add("hide");
          },
        }
      });
    }
    if (jQuery(".hero-agent-video").length > 0) {
      gsap.to('.hero-agent-video', {
        y: 500,
        x: -450,
        scrollTrigger: {
          trigger: '.hero-section',
          start: 'top top',
          end: 'bottom top+=300',
          scrub: true,
          onLeave: () => {
            document.querySelector(".hero-agent-video").classList.add("hide");
          },
        }
      });
    }
    if (jQuery(".showroom-block-ctn").length > 0) {
      gsap.to('.showroom-block-ctn', {
        scrollTrigger: {
          trigger: '.showroom-block-ctn',
          start: 'top top+=500',
          end: '+=100',
          pin: false,
          pinSpacing: false,
          scrub: false,
          onEnter: () => {
            setTimeout(() => {
              document.querySelector(".showroom-area").classList.add("played");
              jQuery('video').trigger('play');
            }, 100);

          },
        }
      });
    }
    if (jQuery(".agent-video").length > 0) {
      gsap.to('.agent-video', {
        scrollTrigger: {
          trigger: '.showroom-block-ctn',
          start: 'top top+=350',
          end: '+=100',
          scrub: false,
          onEnter: () => {
            document.querySelector(".agent-video").classList.add("agent-animation");
          },
        }
      });
    }
    if (jQuery(".guest-video").length > 0) {
      gsap.to('.guest-video', {
        scrollTrigger: {
          trigger: '.showroom-block-ctn',
          start: 'top top+=350',
          end: '+=100',
          scrub: false,
          onEnter: () => {
            document.querySelector(".guest-video").classList.add("guest-animation");
          },
        }
      });
    }
  }

  const leadParagraphCTN = document.querySelectorAll('.lead-para-ctn');

  leadParagraphCTN.forEach(function (item, index) {
    if (jQuery(' span.span-1').length > 0) {
      gsap.to(' span.span-1', {
        scrollTrigger: {
          trigger: item,
          start: 'top-=200 center',
          endTrigger: item,
          end: '33% center',
          scrub: true,

          pin: false,
          toggleClass: { targets: ' span.span-1', className: 'active' },
        },
        duration: 1,
      });
    }
    if (jQuery(' span.span-2').length > 0) {
      gsap.to(' span.span-2', {
        scrollTrigger: {
          trigger: item,
          start: '33.01% center',
          endTrigger: item,
          end: '66% center',
          scrub: true,
          pin: false,
          toggleClass: { targets: ' span.span-2', className: 'active' },
        },
        duration: 1,
      });
    }
    if (jQuery(' span.span-3').length > 0) {
      gsap.to(' span.span-3', {
        scrollTrigger: {
          trigger: item,
          start: '66.01% center',
          endTrigger: item,
          end: '100% center-=200',
          scrub: true,
          pin: false,
          toggleClass: { targets: ' span.span-3', className: 'active' },
        },
        duration: 1,
      });
    }
  });

  jQuery(".logos-slider").owlCarousel({
    margin: 96,
    items: 12,
    nav: false,
    dots: false,
    autoWidth: true,
    loop: true,
    autoplay: true,
    slideTransition: "linear",
    autoplayTimeout: 2500,
    autoplaySpeed: 2500,
    mouseDrag: false,
    responsive: {
      0: {
        margin: 44,
        stagePadding: 39,
      },
      748: {
        margin: 96,
      },
    },
  });

  jQuery(
    (function (jQuery, win) {
      jQuery.fn.inViewport = function (cb) {
        return this.each(function (i, el) {
          function visPx() {
            const H = jQuery(this).height(),
              r = el.getBoundingClientRect(),
              t = r.top,
              b = r.bottom;
            return cb.call(el, Math.max(0, t > 0 ? H - t : b < H ? b : H));
          }
          visPx();
          jQuery(win).on('resize scroll', visPx);
        });
      };
    })(jQuery, window)
  );

  jQuery(function (jQuery) {
    jQuery('.typing-text').inViewport(function (px) {
      //  = Animate numbers
      if (px > 350 && !this.initNumAnim) {
        this.initNumAnim = true; // Set flag to true to prevent re-running the same animation


        jQuery(function () {
          jQuery(".typewrite").typed({
            strings: ["The modern kitchen, adorned with stylish furniture, creates a welcoming atmosphere for cooking and dining. Equipped with essential appliances like a fridge, oven, and microwave, it offers both functionality and aesthetic appeal."],
            typeSpeed: 7
          });
        });


      }
    });
  });

  jQuery('.gallery-slider').owlCarousel({
    items: 2,
    nav: false,
    dots: false,
    loop: true,
    margin: 30,
    stagePadding: 0,
    responsive: {
      0: {
        items: 1,
        margin: 15,
        stagePadding: 15,
      },

      580: {
        items: 2,
        margin: 15,
        stagePadding: 20,
      },
      748: {
        items: 2,
      },
      1004: {
        items: 2,

      },
    }
  });

  // gsap.to()... infinity and beyond!
  // For more check out greensock.com



  if (jQuery(window).width() > 1003) {
    const array = [];
    let html = '';
    jQuery('.testimonials-area').find('.single-testimonial').each(function () {
      array.push('<div class="single-testimonial">' + jQuery(this).html() + '</div>');
    });
    const chunkSize = Math.ceil(jQuery('.testimonials-area').find('.single-testimonial').length / 3);
    const chunk = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunk.push(array.slice(i, i + chunkSize));
    }
    for (const key in chunk) {
      const element = chunk[key];
      html += '<div class="testimonial-column">';
      for (const key in element) {
        const el = element[key];
        html += el;
      }
      html += '</div>';
    }

    jQuery('.testimonials-area').html(html);

  }
  jQuery(function (jQuery) {
    jQuery('.testimonials-area').inViewport(function (px) {
      //  = Animate numbers
      if (px > 400 && !this.initNumAnim) {
        this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
        (function () {
          let chck_if_gsap_loaded = setInterval(function () {
            if (window.gsap && window.ScrollTrigger) {
              gsap.registerPlugin(ScrollTrigger);
              animateImages(cols);
              clearInterval(chck_if_gsap_loaded);
            }
          }, 100);
          const cols = gsap.utils.toArray(".testimonial-column");
          function animateImages(cols) {
            let offset = 0;
            cols.forEach((col, i) => {
              const images = col.childNodes;

              // DUPLICATE IMAGES FOR LOOP
              images.forEach((image) => {
                var clone = image.cloneNode(true);
                col.appendChild(clone);
              });

              // SET ANIMATION
              images.forEach((item) => {
                let columnHeight = item.parentElement.offsetHeight;
                let direction = "-="  // Change direction for odd columns
                let yOffset = direction === "+=" ? -offset : offset;

                let tween = gsap.to(item, {
                  y: direction + Number(columnHeight / 2),
                  duration: 15,
                  repeat: -1,
                  ease: "none",
                  onRepeat: () => {
                    offset = 0;
                  },
                  modifiers: {
                    y: gsap.utils.unitize((y) => {
                      y = (parseFloat(y) + yOffset) % (columnHeight * 0.5);
                      return y;
                    })
                  }
                });
                document.querySelectorAll(".col").forEach((img) => {
                  img.addEventListener("mouseover", () => {
                    if (tween) tween.pause();
                  });

                  img.addEventListener("mouseout", () => {
                    if (tween) tween.resume();
                  });
                });
              });
            });
          }
        })();
      }
    });
  });
  if (jQuery(window).width() < 1003) {
    jQuery('.testimonials-area').owlCarousel({
      items: 8,
      nav: false,
      dots: false,
      responsive: {
        0: {
          items: 1,
          stagePadding: 35,
          margin: 10,
        },

        650: {
          items: 2,
          stagePadding: 30,
          margin: 10,
        },
        1000: {
          items: 2,

        },
      }
    });
  }
  jQuery('.jump-link, a.down-arrow-btn').click(function (e) {
    e.preventDefault();
    var target = jQuery(jQuery(this).attr('href'));
    if (target.length) {
      var scrollTo = target.offset().top;
      jQuery('body, html').animate({ scrollTop: scrollTo + 'px' }, 800);
    }
  });

  if (jQuery(".text-single").length > 0) {
    let loops = gsap.utils.toArray('.text-single').map((line, i) => {
      const links = line.querySelectorAll(".js-text"),
        tl = horizontalLoop(links, {
          repeat: -1,
          speed: 1 + i * 0.5,
          draggable: true,
          reversed: false,
          paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px"))
        });

      links.forEach(link => {
        link.addEventListener("mouseenter", () => gsap.to(tl, { timeScale: 0, overwrite: true }));
        link.addEventListener("mouseleave", () => gsap.to(tl, { timeScale: i ? -1 : 1, overwrite: true }));
      });
    });
    if (jQuery(window).width() < 1003) {
      let loops = gsap.utils.toArray('.text-single').map((line, i) => {
        const links = line.querySelectorAll(".js-text"),
          tl = horizontalLoop(links, {
            repeat: -1,
            speed: 0.5 + i * 0.5,
            draggable: true,
            reversed: false,
            paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px"))
          });

        links.forEach(link => {
          link.addEventListener("mouseenter", () => gsap.to(tl, { timeScale: 0, overwrite: true }));
          link.addEventListener("mouseleave", () => gsap.to(tl, { timeScale: i ? -1 : 1, overwrite: true }));
        });
      });
    }
    let currentScroll = 0;
    let scrollDirection = 1;

    window.addEventListener("scroll", () => {
      let direction = (window.pageYOffset > currentScroll) ? 1 : -1;
      if (direction !== scrollDirection) {
        loops.forEach(tl => gsap.to(tl, { timeScale: direction, overwrite: true }));
        scrollDirection = direction;
      }
      currentScroll = window.pageYOffset;
    });

    function horizontalLoop(items, config) {
      items = gsap.utils.toArray(items);
      config = config || {};
      let tl = gsap.timeline({ repeat: config.repeat, paused: config.paused, defaults: { ease: "none" }, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100) }),
        length = items.length,
        startX = items[0].offsetLeft,
        times = [],
        widths = [],
        xPercents = [],
        curIndex = 0,
        pixelsPerSecond = (config.speed || 1) * 100,
        snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1),
        populateWidths = () => items.forEach((el, i) => {
          widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
          xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / widths[i] * 100 + gsap.getProperty(el, "xPercent"));
        }),
        getTotalWidth = () => items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0),
        totalWidth, curX, distanceToStart, distanceToLoop, item, i;
      populateWidths();
      gsap.set(items, {
        xPercent: i => xPercents[i]
      });
      gsap.set(items, { x: 0 });
      totalWidth = getTotalWidth();
      for (i = 0; i < length; i++) {
        item = items[i];
        curX = xPercents[i] / 100 * widths[i];
        distanceToStart = item.offsetLeft + curX - startX;
        distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
        tl.to(item, { xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
          .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false }, distanceToLoop / pixelsPerSecond)
          .add("label" + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
      }
      function toIndex(index, vars) {
        vars = vars || {};
        (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
        let newIndex = gsap.utils.wrap(0, length, index),
          time = times[newIndex];
        if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
          vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
          time += tl.duration() * (index > curIndex ? 1 : -1);
        }
        curIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
      }
      tl.next = vars => toIndex(curIndex + 1, vars);
      tl.previous = vars => toIndex(curIndex - 1, vars);
      tl.current = () => curIndex;
      tl.toIndex = (index, vars) => toIndex(index, vars);
      tl.updateIndex = () => curIndex = Math.round(tl.progress() * (items.length - 1));
      tl.times = times;
      tl.progress(1, true).progress(0, true); // pre-render for performance
      if (config.reversed) {
        tl.vars.onReverseComplete();
        tl.reverse();
      }
      if (config.draggable && typeof (Draggable) === "function") {
        let proxy = document.createElement("div"),
          wrap = gsap.utils.wrap(0, 1),
          ratio, startProgress, draggable, dragSnap, roundFactor,
          align = () => tl.progress(wrap(startProgress + (draggable.startX - draggable.x) * ratio)),
          syncIndex = () => tl.updateIndex();
        typeof (InertiaPlugin) === "undefined" && console.warn("InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club");
        draggable = Draggable.create(proxy, {
          trigger: items[0].parentNode,
          type: "x",
          onPress() {
            startProgress = tl.progress();
            tl.progress(0);
            populateWidths();
            totalWidth = getTotalWidth();
            ratio = 1 / totalWidth;
            dragSnap = totalWidth / items.length;
            roundFactor = Math.pow(10, ((dragSnap + "").split(".")[1] || "").length);
            tl.progress(startProgress);
          },
          onDrag: align,
          onThrowUpdate: align,
          inertia: true,
          snap: value => {
            let n = Math.round(parseFloat(value) / dragSnap) * dragSnap * roundFactor;
            return (n - n % 1) / roundFactor;
          },
          onRelease: syncIndex,
          onThrowComplete: () => gsap.set(proxy, { x: 0 }) && syncIndex()
        })[0];
      }

      return tl;
    }
  }
  // init Isotope
  var $grid = jQuery('.extensions-tiles-ctn').isotope({
    itemSelector: '.extension-box',
    layoutMode: 'fitRows'
  });
  // filter functions
  var filterFns = {
    // show if number is greater than 50
    numberGreaterThan50: function () {
      var number = jQuery(this).find('.number').text();
      return parseInt(number, 10) > 50;
    },
    // show if name ends with -ium
    ium: function () {
      var name = jQuery(this).find('.name').text();
      return name.match(/ium$/);
    }
  };
  // bind filter button click
  jQuery('.filters-button-group').on('click', 'button', function () {
    var filterValue = jQuery(this).attr('data-filter');
    // use filterFn if matches value
    filterValue = filterFns[filterValue] || filterValue;
    $grid.isotope({ filter: filterValue });
  });
  // change is-checked class on buttons
  jQuery('.button-group').each(function (i, buttonGroup) {
    var $buttonGroup = jQuery(buttonGroup);
    $buttonGroup.on('click', 'button', function () {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      jQuery(this).addClass('is-checked');
    });
  });


  // Faqs script
  jQuery('.faq > span').on('click', function () {
    if (jQuery(this).hasClass('active')) {
      jQuery(this).removeClass('active');
      jQuery(this)
        .siblings('.faq-content')
        .slideUp();
    } else {
      jQuery('.faq > span').removeClass('active');
      jQuery(this).addClass('active');
      jQuery('.faq-content').slideUp();
      jQuery(this)
        .siblings('.faq-content')
        .slideDown();
    }
  });
  jQuery('a.show-demo').magnificPopup({
    type: 'inline',
    midClick: true,
  });
});
if (jQuery(".pricing-block-ctn").length > 0) {
  const tableWrapper = document.querySelector(".three-columns");
  const switchInputs = document.querySelectorAll(".switch-wrapper input");
  const prices = tableWrapper.querySelectorAll(".price");
  const toggleClass = "hide";

  for (const switchInput of switchInputs) {
    switchInput.addEventListener("input", function () {
      for (const price of prices) {
        price.classList.add(toggleClass);
      }
      const activePrices = tableWrapper.querySelectorAll(
        `.price.${switchInput.id}`
      );
      for (const activePrice of activePrices) {
        activePrice.classList.remove(toggleClass);
      }
    });
  }
}
